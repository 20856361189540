import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Moment from 'moment';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#233555',//theme.palette.common.black,
        color: theme.palette.common.white,
        fontWeight: 'bold'

    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

///new code
const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});


function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();

    Row.propTypes = {
        row: PropTypes.shape({
            statementId: PropTypes.string.isRequired,
            companyName: PropTypes.string.isRequired,
            userName: PropTypes.string.isRequired,
            userId: PropTypes.number.isRequired,
            //history: PropTypes.arrayOf(
            //    PropTypes.shape({
            //        amount: PropTypes.number.isRequired,
            //        customerId: PropTypes.string.isRequired,
            //        date: PropTypes.string.isRequired,
            //    }),
            //).isRequired,
            skillName: PropTypes.string.isRequired,
            date: PropTypes.string.isRequired,
            passFailStatus: PropTypes.number.isRequired,
            expirationDate: PropTypes.string.isRequired,
            payload: PropTypes.string.isRequired,
        }).isRequired,
    };

    return (
        <React.Fragment>
            <StyledTableRow key={row.statementId} className={classes.root}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                        <StyledTableCell component="th" scope="row">
                    {row.companyName}
                        </StyledTableCell>
                <StyledTableCell>{row.userName} ({row.userId})</StyledTableCell>
                <StyledTableCell>{row.skillName}</StyledTableCell>
                <StyledTableCell>{row.date}</StyledTableCell>
                <StyledTableCell>{row.passFailStatus}</StyledTableCell>
                <StyledTableCell>{row.expirationDate ? Moment(row.expirationDate).format('MM-DD-yy') : null}</StyledTableCell>
                    </StyledTableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div">
                                Payload
                            </Typography>
                            <Table size="small" aria-label="payload">
                                <TableBody>
                                    <TableRow key={row.payload}>
                                            <TableCell>
                                            {row.payload}
                                            </TableCell>
                                        </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

///new collapse code


const StyledPayloadTableCell = withStyles((theme) => ({
    root: {
        width: 50,
    },
    body: {
        //fontSize: 10,
        display: 'block',
        width: 250,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
}))(TableCell);


const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

function createData(cname, uname, skname, date, status, expdate, payload) {
    return { cname, uname, skname, date, status, expdate, payload };
}

//const useStyles = makeStyles({
//    table: {
//        minWidth: 700,
//        width: '95%',
//        margin: 'auto'
//    },
//});
const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
        width: '95%',
        margin: 'auto'
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
}));
export default function ReportApp() {
    const [error, setError] = React.useState("");
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [items, setItems] = React.useState([]);
    const [companyId, setCompanyId] = React.useState(1);
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    //timeout to get entered value
    const [query, setQuery] = React.useState("");
    React.useEffect(() => {
        if (query === "") return;
        const timeOutId = setTimeout(() => setCompanyId(query), 500);
        return () => clearTimeout(timeOutId);
    }, [query]);

    // Note: the empty deps array [] means
    // this useEffect will run once
    // similar to componentDidMount()
    React.useEffect(() => {
        var tempToken = "";
        // iterate localStorage
        for (var i = 0; i < localStorage.length; i++) {

            // set iteration key name
            var key = localStorage.key(i);

            // use key name to retrieve the corresponding value
            var value = localStorage.getItem(key);

            if (key.indexOf('accessToken') > 0 && key.startsWith('CognitoIdentity')) {
                // console.log the iteration key and value
                console.log('Key: ' + key + ', Value: ' + value);
                tempToken = value;
            }

        }
        tempToken = "eyJraWQiOiJ1MUpCRFZ5eGJ0T1MzcFBSTm9JdWJjYUs5ZjJYQ2dlOXJyTXJWQjEzc0J3PSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI2OGR2bDhka3U5YXBqMDU3NmxxNHFyMnVvaiIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiaHR0cHM6XC9cL2l0cy10cmFpbmluZy5jb21cL2FjdGl2aXR5LndyaXRlIiwiYXV0aF90aW1lIjoxNjI4Nzk3MjcwLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtd2VzdC0yLmFtYXpvbmF3cy5jb21cL3VzLXdlc3QtMl9GNTRqNm0zVDIiLCJleHAiOjE2Mjg4ODM2NzAsImlhdCI6MTYyODc5NzI3MCwidmVyc2lvbiI6MiwianRpIjoiOWQyYzI4MzctMDk4Zi00NzE2LWE4ZTYtMzY3ZTgzYjc2MWQ4IiwiY2xpZW50X2lkIjoiNjhkdmw4ZGt1OWFwajA1NzZscTRxcjJ1b2oifQ.LPxR-HtBnOAXyth-cvgjEtwI1EQsrlQIbEWw6YQJl4A1MeW0UIBHS61l-PeJmOPI7SndTaOlcBrCS01_yIkbfo9FVDRkvGdt2yMb5EdFSd-FJqJKkXH3F2_eFfMs2oZTMZCyN7VJ8QKL2UWI0lQVBxizW3uVzpftB9Ts5z_oLXgbj-a9ZpTkPjBP9nzV1BRTPg85MnVuqGECj4PGCFj3Aaf_XgZUz4VnxYN4W5A2aNsqe2vkXgVaTIA0o1HhaR0ChaeHp18zb7Wzm5iTTm23Tvo_VUoVMSJ7lWb7catu64PYpxwvfa5G9lO-PFzFS3hjGPQBy57lKxcOT74JW2xS-g";

    // // Make the POST request to get the access token
    // console.info(`requesting token from auth service using client_credentials auth flow: https://onboard-dev.auth.us-west-2.amazoncognito.com/oauth2/token`);
    // const response = fetch('https://onboard-dev.auth.us-west-2.amazoncognito.com/oauth2/token', {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/x-www-form-urlencoded",
    //     "Authorization": `Basic ${encodedCreds}`
    //   },
    //   // node-fetch will encode the body automatically
    //   body: params
    // })
    // .then(res => res.json())
    // .then(json => {
    //   if (json.error) {
    //     console.info(json); //log error        
    //   }
    //   else {
    //     //console.info(`success login: ${JSON.stringify(json)}`);
    //     console.info(`successfully retrieved auth token: ${json}`);
    //     tempToken = json.access_token;      
    //   }
    // })
    // .catch(err => {
    //   console.error(`error: ${err}`);      
    // });  
tempToken = 'eyJraWQiOiJ1MUpCRFZ5eGJ0T1MzcFBSTm9JdWJjYUs5ZjJYQ2dlOXJyTXJWQjEzc0J3PSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI2OGR2bDhka3U5YXBqMDU3NmxxNHFyMnVvaiIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiaHR0cHM6XC9cL2l0cy10cmFpbmluZy5jb21cL2FjdGl2aXR5LndyaXRlIiwiYXV0aF90aW1lIjoxNjI5MjEzNDc4LCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtd2VzdC0yLmFtYXpvbmF3cy5jb21cL3VzLXdlc3QtMl9GNTRqNm0zVDIiLCJleHAiOjE2MjkyOTk4NzgsImlhdCI6MTYyOTIxMzQ3OCwidmVyc2lvbiI6MiwianRpIjoiYTQyYzNiNGQtNWUxNi00ZmE4LWEwNjUtNGIxZDYzYTZmYjllIiwiY2xpZW50X2lkIjoiNjhkdmw4ZGt1OWFwajA1NzZscTRxcjJ1b2oifQ.OpjCtML-cXZ1A84hs9xsctRqEwLI0QXt13fnQ_3CkQi1AXGuRkOnS7d5tb71J8vXWrCmiyAhGsrscFLtmEIK5GXE_kU1rRGEkdaizNvJFPp5oCriQpb4pxR4Ec_WNAlfqlCXviOrKcNxCsAcNQU9OoJZwi3pjhAES7Pq95pI1OOM4wYLpzly2ugMcOpH7WsFpQ7walvKeTDfFAK2EaUDV5qfQd0pSBkeRf5Oa6y9fd99CkQq9-FksNL3pZj7H7SZSYwWFe1_IH6T7tb0IRzMe_G5NmfU-e6FeMoOGzpWq5lYc9cY7zfsdd15QDH841o0LPIrog4xRwI4JkGxI2ZGIw';
        console.log(tempToken);
        fetch("https://api-dev.its-training.com/activities/a/" + companyId + "/reports", {
            method: 'GET',
            headers: {
                Accept: 'text/json',
                Authorization: 'Bearer ' + tempToken,

            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.status === 404) {
                        setIsLoaded(false);
                        setError("No Data Found");
                    }
                    else
                    {
                        setError("");
                        setIsLoaded(true);
                        setItems(result);
                        
                    }
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [companyId])

    if (error !== "") {
        return <div><form className={classes.root} noValidate autoComplete="off">
            <TextField id="outlined-basic" label="Enter Company Id" variant="outlined" onChange={
                event => setQuery(event.target.value)
            } />
        </form>Error: {error}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {
        return (
            <div>
                <form className={classes.root} noValidate autoComplete="off">
                    <TextField id="outlined-basic" label="Enter Company Id" variant="outlined" onChange={
                        event => setQuery(event.target.value) 
                    } />
                </form>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell />
                                <StyledTableCell>Company Name</StyledTableCell>
                                <StyledTableCell>User Name (User Id)</StyledTableCell>
                                <StyledTableCell>Skill Name</StyledTableCell>
                                <StyledTableCell>Date</StyledTableCell>
                                <StyledTableCell>Status (Pass/Fail)</StyledTableCell>
                                <StyledTableCell>Expiration Date</StyledTableCell>
                            {/*    <StyledTableCell>Payload</StyledTableCell>*/}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map((recordrow) => (
                                <Row key={recordrow.statementId} row={recordrow} />
                            ))}
                            {/*{items.map((recordrow) => (*/}
                            {/*    <StyledTableRow key={recordrow.statementId}>*/}
                            {/*        <StyledTableCell component="th" scope="row">*/}
                            {/*            {recordrow.companyName}*/}
                            {/*        </StyledTableCell>*/}
                            {/*        <StyledTableCell>{recordrow.userName} ({recordrow.userId})</StyledTableCell>*/}
                            {/*        <StyledTableCell>{recordrow.skillName}</StyledTableCell>*/}
                            {/*        <StyledTableCell>{recordrow.date}</StyledTableCell>*/}
                            {/*        <StyledTableCell>{recordrow.passFailStatus}</StyledTableCell>*/}
                            {/*        <StyledTableCell>{recordrow.expirationDate}</StyledTableCell>*/}
                            {/*        <StyledPayloadTableCell>{recordrow.payload}</StyledPayloadTableCell>*/}
                            {/*    </StyledTableRow>*/}
                            {/*))}*/}
                        </TableBody>
                    </Table>
                </TableContainer>


            </div>
        );
    }
};

