import React from 'react';
import './App.css';
import Amplify from 'aws-amplify';
import { AmplifyAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import ReportApp from './report'

// Amplify.configure(
//   {
//     "aws_cognito_region": "us-west-2", 
//     "aws_user_pools_id": "us-west-2_F54j6m3T2",
//     "aws_user_pools_web_client_id": "66ma0cum0cmacumm3se5bctvtq", //app client must not have secret key
//     "aws_scopes": "https://its-training.com/activity.write"
// });
Amplify.configure({
  Auth: {

    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    //identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',
  
    // REQUIRED - Amazon Cognito Region
    region: 'us-west-2',
  
    // OPTIONAL - Amazon Cognito Federated Identity Pool Region 
    // Required only if it's different from Amazon Cognito Region
    //identityPoolRegion: 'XX-XXXX-X',
  
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'us-west-2_F54j6m3T2',
  
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '66ma0cum0cmacumm3se5bctvtq', //68dvl8dku9apj0576lq4qr2uoj
  
    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    //mandatorySignIn: false,
  
    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    // cookieStorage: {
    // // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    //     domain: '.yourdomain.com',
    // // OPTIONAL - Cookie path
    //     path: '/',
    // // OPTIONAL - Cookie expiration in days
    //     expires: 365,
    // // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
    //     sameSite: "strict" | "lax",
    // // OPTIONAL - Cookie secure flag
    // // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
    //     secure: true
    // },
  
    // OPTIONAL - customized storage object
    //storage: MyStorage,
  
    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    //authenticationFlowType: 'USER_PASSWORD_AUTH',
  
    // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
    //clientMetadata: { myCustomKey: 'myCustomValue' },
  
     // OPTIONAL - Hosted UI configuration
    oauth: {
        //domain: 'your_cognito_domain',
        scope: ['https://its-training.com/activity.write'],
        //redirectSignIn: 'http://localhost:3000/',
        //redirectSignOut: 'http://localhost:3000/',
        //responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
  
  }}
  );

//auth
const AuthStateApp = () => {
    const [authState, setAuthState] = React.useState();
    const [user, setUser] = React.useState();

    React.useEffect(() => {
        return onAuthUIStateChange((nextAuthState, authData) => {
            setAuthState(nextAuthState);
            setUser(authData)
        });
    }, []);

    //data list
    const DataComponent = () => {
      const [error, setError] = React.useState(null);
      const [isLoaded, setIsLoaded] = React.useState(false);
      const [items, setItems] = React.useState([]);
        
      // Note: the empty deps array [] means
      // this useEffect will run once
      // similar to componentDidMount()
      React.useEffect(() => {
        //let accessToken = eval("CognitoIdentityServiceProvider.66ma0cum0cmacumm3se5bctvtq.Angelo_test10.accessToken");
        // console.log('useEffect');
        // Auth.currentCredentials().then(creds => console.log(creds));
        // const federatedInfo = Cache.getItem('federatedInfo');
        // console.log(federatedInfo);
        // const { token } = federatedInfo;
        //  console.log(JSON.stringify(token));
          var tempToken = "";
          // iterate localStorage
          for (var i = 0; i < localStorage.length; i++) {

              // set iteration key name
              var key = localStorage.key(i);

              // use key name to retrieve the corresponding value
              var value = localStorage.getItem(key);

              if (key.indexOf('accessToken') > 0 && key.startsWith('CognitoIdentity')) {
                  // console.log the iteration key and value
                  console.log('Key: ' + key + ', Value: ' + value);
                  tempToken = value;
              }

          }
          //const accessToken = localStorage.getItem('CognitoIdentityServiceProvider.66ma0cum0cmacumm3se5bctvtq.Angelo_test10.accessToken');
          tempToken = "eyJraWQiOiJ1MUpCRFZ5eGJ0T1MzcFBSTm9JdWJjYUs5ZjJYQ2dlOXJyTXJWQjEzc0J3PSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI2OGR2bDhka3U5YXBqMDU3NmxxNHFyMnVvaiIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiaHR0cHM6XC9cL2l0cy10cmFpbmluZy5jb21cL2FjdGl2aXR5LndyaXRlIiwiYXV0aF90aW1lIjoxNjI4Nzk3MjcwLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtd2VzdC0yLmFtYXpvbmF3cy5jb21cL3VzLXdlc3QtMl9GNTRqNm0zVDIiLCJleHAiOjE2Mjg4ODM2NzAsImlhdCI6MTYyODc5NzI3MCwidmVyc2lvbiI6MiwianRpIjoiOWQyYzI4MzctMDk4Zi00NzE2LWE4ZTYtMzY3ZTgzYjc2MWQ4IiwiY2xpZW50X2lkIjoiNjhkdmw4ZGt1OWFwajA1NzZscTRxcjJ1b2oifQ.LPxR-HtBnOAXyth-cvgjEtwI1EQsrlQIbEWw6YQJl4A1MeW0UIBHS61l-PeJmOPI7SndTaOlcBrCS01_yIkbfo9FVDRkvGdt2yMb5EdFSd-FJqJKkXH3F2_eFfMs2oZTMZCyN7VJ8QKL2UWI0lQVBxizW3uVzpftB9Ts5z_oLXgbj-a9ZpTkPjBP9nzV1BRTPg85MnVuqGECj4PGCFj3Aaf_XgZUz4VnxYN4W5A2aNsqe2vkXgVaTIA0o1HhaR0ChaeHp18zb7Wzm5iTTm23Tvo_VUoVMSJ7lWb7catu64PYpxwvfa5G9lO-PFzFS3hjGPQBy57lKxcOT74JW2xS-g";
          console.log(tempToken);
          fetch("https://api-dev.its-training.com/activities/a/statements?limit=10",{
            method: 'GET',
          headers: {
            Accept: 'text/json',
              Authorization: 'Bearer ' + tempToken,
              
            },
        })
          .then(res => res.json())
          .then(
            (result) => {
              setIsLoaded(true);
              setItems(result.statements);
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
              setIsLoaded(true);
              setError(error);
            }
          )
      }, [])
    
      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return <div>Loading...</div>;
      } else {
          return (
            <div>
          <div class='data-container'>
          <h3>LRS results:</h3>
            {items.map(statement => (
              <p>{statement.timestamp} UTC:&nbsp; &nbsp;
                <span class="actor">{statement.actor.name}</span> 
                <span class="verb">{statement.verb.display['en-US']}</span> 
                <span class="object">{(statement.object.objectType==='SubStatement')? statement.object.object.id : (statement.object.definition && statement.object.definition.desciption && statement.object.definition.desciption.und)? statement.object.definition.description.und: statement.object.id}</span>                 
                {(statement.result && statement.result.score)? " with " + statement.result.score.scaled*100 + "%": ""}.
              </p>
            ))}
                </div>   
                  
                  </div>
        );
      }
    }

  return authState === AuthState.SignedIn && user ? (
      <div className="App">
        <div textAlign='right'><AmplifySignOut /></div>
        <h1>Hello, {user.username}! 💯💯🔥🔥</h1>
        Time now is: { (new Date()).toString() }<br/>
          {/*<DataComponent></DataComponent>*/}

          <ReportApp></ReportApp>

      </div>
    ) : (
      <AmplifyAuthenticator />
  );
}
//<DataComponent/>
export default AuthStateApp;


// //****  */
// import logo from './logo.svg';
// import './App.css';
// import Amplify from "aws-amplify";
// import {AmplifyAuthenticator, AmplifySignOut} from "@aws-amplify/ui-react";



// /// ***** 
// Amplify.configure(
//   {
//     "aws_cognito_region": "us-west-2", 
//     "aws_user_pools_id": "us-west-2_F54j6m3T2",
//     "aws_user_pools_web_client_id": "66ma0cum0cmacumm3se5bctvtq" //app client must not have secret key
// });

// function App() {
//   return (
//     <AmplifyAuthenticator>
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React with ITS
//         </a>
//       </header>
//     </div>
//     </AmplifyAuthenticator>
//   );
// }

// export default App;
